import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Comments from 'remark-ninja-react';

import SEO from '../components/seo';
import Layout from '../components/layout';
import style from './markdown-post.module.scss';

const MarkdownPage = ({ data }) => {
  const { markdownRemark } = data;
  const { frontmatter, html } = markdownRemark;
  return (
    <Layout>
      <SEO title={frontmatter.title} />
      <div>
        <div className={style.postContainer}>
          <h1 className={style.postTitle}>{frontmatter.title}</h1>
          <div className={style.postDate}>{frontmatter.date}</div>
          <div
            className='blog-post-content'
            dangerouslySetInnerHTML={{ __html: html }}
          />
          <h2>Comments</h2>
          <Comments siteId='280804dc-9ad2-4ef8-af71-16b9b80cbbac' />
        </div>
      </div>
    </Layout>
  );
};

MarkdownPage.propTypes = {
  data: PropTypes.object
};

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(fields: { path: { eq: $path } }) {
      html
      frontmatter {
        title
        date(formatString: "YYYY-MM-DD")
      }
    }
  }
`;

export default MarkdownPage;
